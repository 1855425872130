import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Card, Stack, Button, Container, Typography, LinearProgress } from '@mui/material';
import ProductFormModal from 'components/ProductFormModal';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import Iconify from '../components/iconify';

export default function ProductsPage() {
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const {
    data: productsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['products'],
    queryFn: () => apiGenerator('get')('/admin/products'),
  });

  const rows =
    productsData?.data?.map((p) => ({
      id: p._id,
      name: p.name,
    })) || [];

  const columns = [
    { field: 'id', headerName: 'Product ID', flex: 0.1 },
    { field: 'name', headerName: 'Product Name', sortable: false, flex: 0.1 },
  ];

  return (
    <>
      <Helmet>
        <title>Products</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setShowCreateProductModal(true);
            }}
          >
            New Product
          </Button>
        </Stack>

        <Card>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={isLoading}
              rows={rows}
              columns={columns}
              hideFooter
              rowHeight={60}
            />
          </div>
        </Card>
      </Container>
      {showCreateProductModal && (
        <ProductFormModal
          setOpen={setShowCreateProductModal}
          onSuccess={() => {
            refetch();
          }}
        />
      )}
    </>
  );
}
