import { Dialog, DialogContent, DialogTitle, Stack, TextField, Button, DialogActions } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { apiGenerator } from 'utils/apiHelpers';
import * as Yup from 'yup';

const ProductFormModal = ({ setOpen, onSuccess }) => {
  const createProductMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/products', data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      createProductMutation.mutate({
        ...values,
      });
    },
  });

  return (
    <Dialog open>
      <DialogTitle>New Product</DialogTitle>
      <form>
        <DialogContent>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={2}
            sx={{ width: '30rem' }}
          >
            <TextField
              autoFocus
              id={'name'}
              label="Name"
              name="name"
              type="text"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button variant={'contained'} onClick={formik.handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ProductFormModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ProductFormModal;
