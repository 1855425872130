import { Navigate, useRoutes } from 'react-router-dom';
import { GuestRoute } from 'components/GuestRoute';
import UsersPage from 'pages/UsersPage';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from "./pages/ProductsPage";
import OrdersPage from "./pages/OrdersPage";

export default function Router() {
  const routes = useRoutes([
    {
      element: <GuestRoute />,
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
      ],
    },
    {
      element: <AuthenticatedRoute />,
      children: [
        {
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/users" />, index: true },
            { path: 'users', element: <UsersPage /> },
            { path: 'products', element: <ProductsPage /> },
            { path: 'orders', element: <OrdersPage/> },

          ],
        },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
