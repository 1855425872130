import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box, LinearProgress } from '@mui/material';
import UserFormModal from 'components/UserFormModal';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import Iconify from '../components/iconify';

export default function UsersPage() {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const {
    data: usersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['users'],
    queryFn: () => apiGenerator('get')('/admin/users'),
  });

  const rows =
    usersData?.data?.map((user) => ({
      id: user?._id,
      accountId: user?.accountId,
      email: user.email,
      name: user.fullName,
      phoneNumber: user.phoneNumber,
      businessName: user.businessName,
      postalCode: user.postalCode,
      companyRegistrationNumber: user.companyRegistrationNumber,
    })) || [];

  const columns = [
    { field: 'accountId', headerName: 'Account ID', width: 230 },
    { field: 'name', headerName: 'Full Name', sortable: false, width: 150 },
    { field: 'email', headerName: 'Email', sortable: false, width: 200 },
    { field: 'postalCode', headerName: 'Postal Code', sortable: false, width: 150 },
    { field: 'phoneNumber', headerName: 'Phone Number', sortable: false, width: 150 },
    { field: 'businessName', headerName: 'Business Name', sortable: false, width: 150 },
    { field: 'companyRegistrationNumber', headerName: 'Company Regist. No', sortable: false, width: 200 },
  ];

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setShowCreateUserModal(true);
            }}
          >
            New User
          </Button>
        </Stack>

        <Card>
          <Box style={{ height: 500, width: '100%' }}>
            <DataGrid
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={isLoading}
              rows={rows}
              columns={columns}
              hideFooter
              rowHeight={70}
            />
          </Box>
        </Card>
      </Container>

      {showCreateUserModal && (
        <UserFormModal
          setOpen={setShowCreateUserModal}
          onSuccess={() => {
            refetch();
          }}
        />
      )}
    </>
  );
}
