import React from 'react';
import { Dialog, DialogContent, DialogTitle, Stack, TextField, Button, DialogActions } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { apiGenerator } from 'utils/apiHelpers';
import * as Yup from 'yup';

const UserFormModal = ({ setOpen, onSuccess }) => {
  const createUserMutation = useMutation({
    mutationFn: (data) => apiGenerator('post')('/admin/users', data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });
  const schema = Yup.object().shape({
    fullName: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    businessName: Yup.string().min(2, 'Too Short!').required('Required'),
    companyRegistrationNumber: Yup.string().min(2, 'Too Short!').required('Required'),
    phoneNumber: Yup.string().min(2, 'Too Short!').required('Required'),
    postalCode: Yup.string().min(2, 'Too Short!').required('Required'),
    password: Yup.string().min(2, 'Too Short!').required('Required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      fullName: '',
      businessName: '',
      companyRegistrationNumber: '',
      phoneNumber: '',
      postalCode: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      createUserMutation.mutate({
        ...values,
      });
    },
  });

  return (
    <Dialog open>
      <DialogTitle>New User</DialogTitle>
      <form>
        <DialogContent>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={2}
            sx={{ width: '30rem' }}
          >
            <TextField
              autoFocus
              label="Email"
              id="email"
              name="email"
              type="text"
              fullWidth
              variant={'outlined'}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              label="Password"
              id="password"
              name="password"
              type="text"
              fullWidth
              variant={'outlined'}
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              label="Full Name"
              name="fullName"
              id="fullName"
              type="text"
              fullWidth
              variant={'outlined'}
              onChange={formik.handleChange}
              value={formik.values.fullName}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
            <TextField
              label="Business Name"
              name="businessName"
              id="businessName"
              type="text"
              fullWidth
              variant={'outlined'}
              onChange={formik.handleChange}
              value={formik.values.businessName}
              error={formik.touched.businessName && Boolean(formik.errors.businessName)}
              helperText={formik.touched.businessName && formik.errors.businessName}
            />
            <TextField
              label="Company Registration Number"
              name="companyRegistrationNumber"
              id="companyRegistrationNumber"
              type="text"
              fullWidth
              variant={'outlined'}
              onChange={formik.handleChange}
              value={formik.values.companyRegistrationNumber}
              error={formik.touched.companyRegistrationNumber && Boolean(formik.errors.companyRegistrationNumber)}
              helperText={formik.touched.companyRegistrationNumber && formik.errors.companyRegistrationNumber}
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              id="phoneNumber"
              type="text"
              fullWidth
              variant={'outlined'}
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
            <TextField
              label="Postal Code"
              name="postalCode"
              id="postalCode"
              type="text"
              fullWidth
              variant={'outlined'}
              onChange={formik.handleChange}
              value={formik.values.postalCode}
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button variant={'contained'} onClick={formik.handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

UserFormModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default UserFormModal;
