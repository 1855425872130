import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Button,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { apiGenerator } from 'utils/apiHelpers';

const UpdateOrderModal = ({ order, setOpen, onSuccess }) => {
  const updateOrderMutation = useMutation({
    mutationFn: (data) => apiGenerator('put')(`/admin/orders/${order?.id}`, data),
    onSuccess: () => {
      setOpen(false);
      onSuccess();
    },
  });

  const formik = useFormik({
    initialValues: {
      adminNote: order?.adminNote,
      status: order?.status,
    },
    onSubmit: (values) => {
      updateOrderMutation.mutate({
        ...values,
      });
    },
  });

  return (
    <Dialog open>
      <DialogTitle>Order Status Update</DialogTitle>
      <form>
        <DialogContent>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={2}
            sx={{ width: '30rem' }}
          >
            <TextField
              autoFocus
              id={'adminNote'}
              label="Note"
              name="adminNote"
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.adminNote}
            />

            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                name="status"
                value={formik.values.status}
                label="Status"
                onChange={formik.handleChange}
              >
                <MenuItem value={'pending'}>Pending</MenuItem>
                <MenuItem value={'inProgress'}>In progress</MenuItem>
                <MenuItem value={'completed'}>Completed</MenuItem>
                <MenuItem value={'cancelled'}>Canceled</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button variant="contained" onClick={() => formik.handleSubmit()}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

UpdateOrderModal.propTypes = {
  order: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default UpdateOrderModal;
