import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Stack, Container, Typography, IconButton, Box, LinearProgress, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { apiGenerator } from 'utils/apiHelpers';
import EditIcon from '@mui/icons-material/Edit';
import { format, parseISO } from 'date-fns';
import UpdateOrderModal from '../components/UpdateOrderModal';

export default function OrdersPage() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const {
    data: ordersData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['orders'],
    queryFn: () => apiGenerator('get')('/admin/orders'),
  });

  const returnChip = (status) => {
    if (status === 'inProgress') {
      return <Chip label={'In Progress'} color="primary" />;
    }
    if (status === 'completed') {
      return <Chip label={'Completed'} color="success" />;
    }
    if (status === 'pending') {
      return <Chip label={'Pending'} color="warning" />;
    }
    return <Chip label={'Cancelled'} color="error" />;
  };

  const rows =
    ordersData?.data?.map((order) => ({
      id: order._id,
      accountId: order.user?.accountId,
      plate: order.plate,
      user: order.user?.fullName,
      email: order.user?.email,
      status: order.status,
      products: order.products,
      userNote: order.userNote,
      adminNote: order?.adminNote,
      createdAt: order.createdAt,
    })) || [];

  const columns = [
    { field: 'id', headerName: 'Order ID', width: 150 },
    { field: 'accountId', headerName: 'Account ID', width: 150 },
    { field: 'plate', headerName: 'Reg', width: 150 },
    { field: 'user', headerName: 'User Name', sortable: false, width: 150 },
    { field: 'email', headerName: 'Email', sortable: false, width: 200 },
    {
      field: 'products',
      headerName: 'Products',
      sortable: false,
      width: 150,
      renderCell: ({ row }) => (
        <Box ml={'4px'}>
          <ul>
            {row.products.map((p) => {
              return <li>{p.name} </li>;
            })}
          </ul>
        </Box>
      ),
    },
    {
      field: 'userNote',
      headerName: 'Order Note',
      sortable: false,
      width: 200,
      renderCell: ({ row }) => <Typography style={{ textWrap: 'wrap' }}>{row.userNote}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 150,
      renderCell: ({ row }) => {
        return returnChip(row.status);
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: false,
      width: 150,
      renderCell: ({ row }) => <Typography>{format(parseISO(row.createdAt), 'dd/MM/yyyy')}</Typography>,
    },
    {
      field: 'adminNote',
      headerName: 'My Note',
      sortable: false,
      width: 200,
      renderCell: ({ row }) => <Typography style={{ textWrap: 'wrap' }}>{row?.adminNote}</Typography>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 100,
      renderCell: ({ row }) => (
        <Box display={'flex'}>
          <IconButton onClick={() => setSelectedOrder(row)}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
        </Stack>

        <Card>
          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={isLoading}
              rows={rows}
              columns={columns}
              hideFooter
              rowHeight={120}
            />
          </div>
        </Card>
      </Container>
      {selectedOrder && <UpdateOrderModal setOpen={setSelectedOrder} order={selectedOrder} onSuccess={refetch} />}
    </>
  );
}
