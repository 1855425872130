import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box } from '@mui/material';
import Logo from '../components/logo';
import { LoginForm } from '../sections/auth/login';
import useResponsive from '../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Insuppa Backoffice </title>
      </Helmet>
      <Container>
        <StyledRoot>
          <Logo sx={{ width: isMobile ? 100 : 400 }} />
          <StyledContent>
            <Typography variant="h4" gutterBottom mb={2}>
              Sign in
            </Typography>
            <LoginForm />
          </StyledContent>
        </StyledRoot>
      </Container>
    </>
  );
}
