import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { apiGenerator } from 'utils/apiHelpers';
import { setAccessTokenInLocalStorage } from 'helpers/storage.helpers';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const getUser = () => {
    apiGenerator('get')('/users/me')
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        navigate('/login', { replace: true });
      });
  };

  const login = async (data) => {
    const body = {
      ...data,
    };
    apiGenerator('post')('/admin/login', body)
      .then((res) => {
        setAccessTokenInLocalStorage(res.data.token);
        getUser();
      })
      .catch((err) => {
        console.log(err);
      });

    // navigate('/dashboard/profile', { replace: true });
  };

  const logout = () => {
    setUser(null);
    setAccessTokenInLocalStorage(null);
    navigate('/login', { replace: true });
  };

  return <AuthContext.Provider value={{ user, login, logout, getUser }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const useAuth = () => useContext(AuthContext);
